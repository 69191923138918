import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { Link, Navigate } from "react-router-dom";

export default class Jobs extends Component {
  state = {
    allJobs: [],
    message: "",
    notification: false,
    noticolor: "success",
    per_pge: 20,
    page_no: 1,
    redirect: false,
  };
  componentDidMount() {
    this.getjobsList();
  }

  getjobsList = () => {
    if (init() === "success") {
      // console.log("called");
      axios
        .post(Constants.postUrls.jobsList)
        .then((resp) => {
          console.log(resp);
          if (resp.data.status === true) {
            this.setState({
              allJobs: resp.data.data,
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                message: "",
              });
            }, 2500);
          } else {
            this.setState({ notification: true, message: resp.data.message });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            notification: true,
            message: err.response?.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
            });
          }, 2500);
        });
    } else {
      window.location.assign("/signin");
    }
  };

  handleJobPublish = (job_id, status) => {
    console.log("function called");

    if (init() === "success") {
      axios.put(Constants.putUrls.jobPublish, { job_id, job_post_status: status }).then((resp) => {
        this.setState(
          {
            notification: true,
            noticolor: "success",
            message: resp.data.message,
          },
          () => {
            this.getjobsList();
          }
        );
        setTimeout(() => {
          this.setState({
            notification: false,
            message: "",
          });
        }, 2500);
      });
    }
  };
  render() {
    if (this.state.redirect === true) {
      return <Navigate to="/signin" replace={true} />;
    } else {
      return (
        <div>
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Jobs List
                  </MDTypography>
                </MDBox>
                <Grid item sm={4} px={2} align="right">
                  <Link to="/addNewJob">
                    <MDButton variant="contained" size="small" color="primary">
                      Create Job
                    </MDButton>
                  </Link>
                </Grid>
                <MDBox bgColor="white">
                  <DataTable
                    table={{
                      columns: [
                        { Header: "serial", accessor: "serial", width: "6%" },
                        { Header: "name", accessor: "job_profile" },
                        { Header: "city", accessor: "city_name" },
                        { Header: "state", accessor: "state_name" },
                        { Header: "action", accessor: "action" },
                        { Header: "status", accessor: "status" },
                        { Header: "applicants", accessor: "view_applicants" },
                      ],
                      rows: this.state.allJobs.map((job, index) => ({
                        serial: index + 1,
                        job_profile: job.job_profile,
                        city_name: job.city_name,
                        state_name: job.state_name,
                        action: (
                          <>
                            <Link
                              to={{
                                pathname: "/updateJob/" + job.job_id,
                                state: { data: job },
                              }}
                            >
                              <MDButton variant="outlined" size="small" color="info">
                                Update
                              </MDButton>
                            </Link>
                          </>
                        ),
                        status: (
                          <>
                            <FormControl fullWidth>
                              <InputLabel>Jobs Post Status</InputLabel>
                              <Select
                                value={job?.job_post_status}
                                onChange={(e) => this.handleJobPublish(job.job_id, e.target.value)}
                                label="Jobs Post Status"
                                name="job_post_status"
                                style={{
                                  height: "45px",
                                }}
                              >
                                <MenuItem value="published">Published</MenuItem>
                                <MenuItem value="unpublished">Un-Published</MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        ),
                        view_applicants: (
                          <>
                            <Link
                              to={{
                                pathname: "/jobApplicants/" + job.job_id,
                                state: { data: job },
                              }}
                            >
                              <MDButton variant="contained" size="small" color="dark">
                                Applicants
                              </MDButton>
                            </Link>
                          </>
                        ),
                      })),
                    }}
                  />
                </MDBox>
                <>
                  <MDSnackbar
                    color={this.state.noticolor}
                    icon="notifications"
                    title="Pro-Rider Jobs"
                    content={this.state.message}
                    dateTime="Just now"
                    open={this.state.notification}
                    //   close={toggleSnackbar}
                  />
                </>
              </Card>
            </MDBox>
          </DashboardLayout>
        </div>
      );
    }
  }
}
