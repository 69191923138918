import React, { Component } from "react";

import { Link, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/signIn.png";
import logo from "assets/images/logoMain.png";

// Constants Imports
import Constants from "../Helpers/Constants";
import axios from "axios";
import MDSnackbar from "components/MDSnackbar";

export default class Signin extends Component {
  state = {
    rememberMe: "",
    username: "",
    password: "",
    redirect: false,
    message: "",
    notification: false,
    noticolor: "success",
  };

  componentDidMount() {}
  handleSetRememberMe = (e) => {
    e.preventDefault();
    this.setState({
      rememberMe: e.target.value,
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleLogin = (e) => {
    e.preventDefault();
    let payload = {
      username: this.state.username,
      password: this.state.password,
    };
    // console.log(Constants.postUrls.employerLogin);
    // this.setState({
    //     redirect: true,
    // });

    axios
      .post(Constants.postUrls.employerLogin, payload)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status == true) {
          localStorage.setItem("accessToken", resp.data.token);
          this.setState({
            message: resp.data.message,
            notification: true,
          });
          setTimeout(() => {
            this.setState(
              {
                message: "",
                notification: false,
                redirect: true,
              },
              () => {
                window.location.assign("/dashboard");
              }
            );
          }, 3000);
        }
      })
      .catch((err) => {
        this.setState({
          message: err.response.data.message,
          notification: true,
          noticolor: "error",
        });
        setTimeout(() => {
          this.setState({
            notification: false,
            message: err.response.data.message,
            noticolor: "success",
          });
        }, 4000);
      });
  };

  render() {
    if (this.state.redirect == true) {
      return <Navigate to="/" />;
    } else {
      return (
        <>
          {/* <img
            src={logo}
            style={{
              zIndex: "99999",
              position: "absolute",
              margin: "10px",
            }}
          /> */}
          <BasicLayout image={bgImage}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
                coloredShadow="dark"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDBox component="img" src={logo} alt="Brand" height="80px" />
                {/* <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign in
                </MDTypography> */}
                {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                      <Grid item xs={2}>
                        <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                          <FacebookIcon color="inherit" />
                        </MDTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                          <GitHubIcon color="inherit" />
                        </MDTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                          <GoogleIcon color="inherit" />
                        </MDTypography>
                      </Grid>
                    </Grid> */}
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={this.handleLogin}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Username"
                      fullWidth
                      name="username"
                      onChange={this.handleInput}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Password"
                      fullWidth
                      name="password"
                      onChange={this.handleInput}
                    />
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={this.state.rememberMe} onChange={this.handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={this.handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="warning" fullWidth type="submit">
                      sign in
                    </MDButton>
                  </MDBox>

                  <MDSnackbar
                    color={this.state.noticolor}
                    icon="notifications"
                    title="Pro-Rider Jobs"
                    content={this.state.message}
                    dateTime="Just now"
                    open={this.state.notification}
                    //   close={toggleSnackbar}
                  />
                  {/* <MDBox mt={3} mb={1} textAlign="center">
                        <MDTypography variant="button" color="text">
                          Don&apos;t have an account?{" "}
                          <MDTypography
                            component={Link}
                            to="/authentication/sign-up"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                          >
                            Sign up
                          </MDTypography>
                        </MDTypography>
                      </MDBox> */}
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        </>
      );
    }
  }
}
