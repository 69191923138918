import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { Link, Navigate } from "react-router-dom";
import burceMars from "assets/images/bruce-mars.jpg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import AppBar from "@mui/material/AppBar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

export default class ApplicantProfile extends Component {
  state = {
    is_Loading: false,
    tabValue: 0,
    profile_details: {},
    tabName: "basic",
    notification: false,
    noticolor: "success",
    message: "",
  };

  componentDidMount() {
    let id = window.location.pathname.split("/")[2];
    if (id) {
      this.getApplicantDetails(id);
    }
  }

  getApplicantDetails = (id) => {
    if (init() === "success") {
      axios
        .post(Constants.postUrls.getjobApplicantDetails, { user_id: id })
        .then((resp) => {
          console.log(resp);
          this.setState({
            profile_details: resp.data.data,
          });
        })
        .catch((err) => {
          this.setState({
            notification: false,
            message: "Error getting applicant details!",
            noticolor: "warning",
          });
          setTimeout(() => {
            this.setState({
              noticolor: "success",
              notification: false,
              message: "",
            });
          }, 3500);
        });
    }
  };

  handleSetTabValue = (e, value) => {
    this.setState({
      tabValue: value,
    });
  };
  render() {
    return (
      <div>
        <DashboardLayout>
          {/* <DashboardNavbar /> */}
          <MDBox />
          <MDBox mb={3}>
            <Card
              sx={{
                position: "relative",
                // mt: -8,
                // mx: 3,
                py: 2,
                px: 2,
              }}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <MDAvatar
                    src={
                      this.state.profile_details?.profile_pic
                        ? Constants.imageDomain + this.state.profile_details?.profile_pic
                        : burceMars
                    }
                    alt="profile-image"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {this.state.profile_details.full_name}
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {this.state.profile_details.u_email}
                    </MDTypography>
                    <MDTypography variant="h6" color="text" fontWeight="medium">
                      +91-{this.state.profile_details.p_mobile}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item>
                  <div
                    className={`profile_tabs ${this.state.tabName === "basic" ? "activeTab" : ""}`}
                    onClick={() => {
                      this.setState({
                        tabName: "basic",
                      });
                    }}
                  >
                    Basic Details
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className={`profile_tabs ${
                      this.state.tabName === "education" ? "activeTab" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        tabName: "education",
                      });
                    }}
                  >
                    Education & Experience
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className={`profile_tabs ${
                      this.state.tabName === "documents" ? "activeTab" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        tabName: "documents",
                      });
                    }}
                  >
                    Documents
                  </div>
                </Grid>
              </Grid>
              <Card>
                {this.state.tabName == "basic" && (
                  <ProfileInfoCard
                    title="Basic Profile"
                    description="User Profile information"
                    info={{
                      fullName: this.state.profile_details.full_name
                        ? this.state.profile_details.full_name
                        : "Not Available",
                      mobile: this.state.profile_details.p_mobile
                        ? "+91-" + this.state.profile_details.p_mobile
                        : "Not Available",
                      email: this.state.profile_details.u_email
                        ? this.state.profile_details.u_email
                        : "Not Available",
                      gender: this.state.profile_details.gender
                        ? this.state.profile_details.gender
                        : "Not Available",
                      city: this.state.profile_details.city_name
                        ? this.state.profile_details.city_name
                        : "Not Available",
                      state: this.state.profile_details.state_name
                        ? this.state.profile_details.state_name
                        : "Not Available",
                      country: this.state.profile_details.country
                        ? this.state.profile_details.country
                        : "Not Available",
                      preferred_city: "Raipur, Bilaspur, Bhilai",
                    }}
                    // social={[]}
                    action={{ route: "", tooltip: "", showtoolTip: false }}
                    shadow={true}
                  />
                )}
                {this.state.tabName == "education" && (
                  <>
                    <Typography variant="h4" px={2} style={{ color: "#d32eff" }}>
                      Education & Experience
                    </Typography>
                    <MDBox display="flex" py={1} px={2} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Highest Education:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{" "}
                        {this.state.profile_details.qualification
                          ? this.state.profile_details.qualification
                          : "Not Available"}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" py={1} px={2} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Training Required:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.training_required};
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" py={1} px={2} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Previous Work Experience:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.experienc_delv_job}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" py={1} px={2} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Previous Company:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details?.company_name}
                      </MDTypography>
                    </MDBox>
                  </>
                )}

                {this.state.tabName == "documents" && (
                  <>
                    <Typography px={2} variant="h4" style={{ color: "#d32eff" }}>
                      Documents
                    </Typography>
                    <MDBox display="flex" py={1} px={2} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Aadhar Card Verified:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.aadhar_verified}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" py={1} px={2} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Pan Card Verified:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.pan_verified}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" py={1} px={2} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Driving License Verified:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.driving_verified}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" py={1} px={2} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Voter ID Verified:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.voter_verified}
                      </MDTypography>
                    </MDBox>
                    <Typography py={2} px={2} variant="h4" style={{ color: "#d32eff" }}>
                      Bank Details
                    </Typography>
                    <MDBox py={1} px={2} display="flex">
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Bank Name:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.p_bank_name}
                      </MDTypography>
                    </MDBox>
                    <MDBox py={1} px={2} display="flex">
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Account Number:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.p_account_number}
                      </MDTypography>
                    </MDBox>
                    <MDBox py={1} px={2} display="flex">
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        IFSC Code:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.p_ifsc_code}
                      </MDTypography>
                    </MDBox>
                    <MDBox py={1} px={2} display="flex">
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Branch Name:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.bank_branch}
                      </MDTypography>
                    </MDBox>
                    <MDBox py={1} px={2} display="flex">
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Account Holder Name:
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {this.state.profile_details.account_holder_name}
                      </MDTypography>
                    </MDBox>
                  </>
                )}
              </Card>
            </Card>
          </MDBox>
        </DashboardLayout>
      </div>
    );
  }
}
