const mainDomain = "https://api.prorider.in/api/v1/";
// const mainDomain = "http://localhost:5050/api/v1/";
const imageDomain = "https://api/prorider.in";
// const imageDomain = "https://localhost:5050";

const Constants = {
  imageDomain,
  getUrls: {
    getDashboard: mainDomain + "emp/dashboard/dash",
    getCountries: mainDomain + "country/list",
    getStates: mainDomain + "state/list",
    getCities: mainDomain + "city/list",
    getCityHubs: mainDomain + "city/hub-list",
    getQualifications: mainDomain + "qualification/list",
    getAllCompanies: mainDomain + "company/list",
    getSingleJobDetails: mainDomain + "emp/jobs/details",
    getSingleCountry: mainDomain + "country/list/",
    getAllJobApplications: mainDomain + "emp/application/list-application",
  },
  postUrls: {
    employerLogin: mainDomain + "employer/login",
    jobsList: mainDomain + "emp/jobs/list",
    createNewJob: mainDomain + "emp/jobs/createJob",
    getjobWiseApplicants: mainDomain + "emp/application/list",
    getjobApplicantDetails: mainDomain + "emp/application/applicant",
  },
  putUrls: {
    updateJobs: mainDomain + "emp/jobs/updateJob",
    jobPublish: mainDomain + "emp/jobs/jobPublish",
    applicationStatus: mainDomain + "emp/application/applicationStatus",
  },
  deleteUrls: {},
};

export default Constants;
