import React, { Component } from "react";
import Constants from "Helpers/Constants";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import card from "assets/theme/components/card";
import cardContent from "assets/theme/components/card/cardContent";
import init from "Helpers/WindowToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { Link, Navigate } from "react-router-dom";

export default class AllApplications extends Component {
  state = {
    secondaryCategories: [],
    per_page: 20,
    page_no: 1,
    notiColor: "success",
    notification: false,
    message: "",
    loading: false,
  };
  componentDidMount() {
    this.getAllJobApplications();
  }

  getAllJobApplications = () => {
    this.setState({ loading: true });
    if (init() === "success") {
      axios
        .get(Constants.getUrls.getAllJobApplications)
        .then((resp) => {
          // console.log(resp.data.data);
          this.setState({
            secondaryCategories: resp.data.data,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            notiColor: "warning",
            message: "Something went wrong!",
            notification: true,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
              notiColor: "success",
              loading: false,
            });
          }, 3500);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  applicationStatus = (job_id, status) => {
    console.log("function called");

    if (init() === "success") {
      axios
        .put(Constants.putUrls.applicationStatus, { job_id, response_status: status })
        .then((resp) => {
          this.setState(
            {
              notification: true,
              noticolor: "success",
              message: resp.data.message,
            },
            () => {
              this.getAllJobApplications();
            }
          );
          setTimeout(() => {
            this.setState({
              notification: false,
              message: "",
            });
          }, 2500);
        });
    }
  };

  render() {
    return (
      <div>
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  All Job Applications
                </MDTypography>
              </MDBox>
              <Grid item sm={4} px={2} align="right">
                {/* <Link to="/addNewJob">
                  <MDButton variant="contained" size="small" color="primary">
                    Create Job
                  </MDButton>
                </Link> */}
              </Grid>
              <MDBox py={3} bgColor="white">
                {this.state.loading ? (
                  <MDBox>
                    <Grid align="center">
                      <h4>Loading...</h4>
                    </Grid>
                  </MDBox>
                ) : (
                  <>
                    {this.state.secondaryCategories.length > 0 ? (
                      <DataTable
                        table={{
                          columns: [
                            { Header: "serial", accessor: "serial", width: "6%" },
                            { Header: "applicant", accessor: "user_name" },
                            { Header: "name", accessor: "job_profile" },
                            { Header: "city", accessor: "city_name" },
                            { Header: "state", accessor: "state_name" },
                            { Header: "status", accessor: "status" },
                            { Header: "action", accessor: "action" },
                          ],
                          rows: this.state.secondaryCategories.map((sec, index) => ({
                            serial: index + 1,
                            user_name: sec?.full_name,
                            job_profile: sec.profile_name,
                            city_name: sec.city_name,
                            state_name: sec.state_name,
                            status: (
                              <>
                                <FormControl fullWidth>
                                  <InputLabel>Application Status</InputLabel>
                                  <Select
                                    value={sec?.response_status}
                                    onChange={(e) => this.applicationStatus(sec.id, e.target.value)}
                                    label="Application Status"
                                    name="response_status"
                                    style={{
                                      height: "45px",
                                      color: "red",
                                    }}
                                  >
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="hired">Hired</MenuItem>
                                    <MenuItem value="rejected">Rejected</MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            ),
                            action: (
                              <>
                                <Link
                                  to={{
                                    pathname: "/applicant-details/" + sec.user_id,
                                    state: { data: sec },
                                  }}
                                >
                                  <MDButton variant="outlined" size="small" color="dark">
                                    User Details
                                  </MDButton>
                                </Link>
                              </>
                            ),
                          })),
                        }}
                      />
                    ) : (
                      <div>No Data Found!</div>
                    )}
                  </>
                )}
              </MDBox>
              <>
                <MDSnackbar
                  color={this.state.notiColor}
                  icon="notifications"
                  title="Pro-Rider Jobs"
                  content={this.state.message}
                  dateTime="Just now"
                  open={this.state.notification}
                  //   close={toggleSnackbar}
                />
              </>
            </Card>
          </MDBox>
        </DashboardLayout>
      </div>
    );
  }
}
