import { isAuth, authToken } from "./AuthHelpers";

const init = () => {
  let w = window;
  let status = false;
  w.axios = require("axios");
  // console.log("axios instance:", w.axios); // Debugging

  if (isAuth) {
    // console.log(authToken);
    console.log("User is authenticated."); // Debugging
    w.axios.defaults.headers.common["Authorization"] = authToken;
    status = true;
  } else {
    console.log("User is not authenticated."); // Debugging
    status = false;
  }
  return status ? "success" : "failed";
};

export default init;
